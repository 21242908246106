import React, { createContext, useMemo, useState } from 'react'

// Bu default değerleri vermezsen build olmuyor
export const BreadcrumbsContext = createContext({
    breadcrumbdata: [],
    setBreadcrumbdata: () => {}
});

const BreadcrumbsContextProvider = ({ children }) => {
    const [breadcrumbdata, setBreadcrumbdata] = useState();
    const value = useMemo(() => ({ breadcrumbdata, setBreadcrumbdata }), [breadcrumbdata]);
    return (
        <BreadcrumbsContext.Provider value={value}>
            {children}
        </BreadcrumbsContext.Provider>
    )
}

export default BreadcrumbsContextProvider;
