
/** UZLASMA End Points.. */
//export const TRENDYOL_API = "https://trendyolindirimuzmaniappservice.azurewebsites.net/trn/";
//export const AUTH_API = "https://authindirimuzmaniappservice.azurewebsites.net/auth/";
//export const TRENDYOL_API = "http://localhost:8085/trn/";
//export const AUTH_API = "http://localhost:8086/auth/";
export const TRENDYOL_API = process.env.GATSBY_TRENDYOL_API;
export const HEPSIBURADA_API = process.env.GATSBY_HEPSIBURADA_API;
export const AMAZON_API = process.env.GATSBY_AMAZON_API;
export const AUTH_API = process.env.GATSBY_AUTH_API;
const UZLASMA_SERVICE_URL = process.env.REACT_APP_UZLASMA_SERVICE_URL;
const UZLASMA_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const CHECK_MERNIS_SORGU = UZLASMA_SERVICE_URL;
export const EVDO_YSICIL_SORGULAMA = UZLASMA_SERVICE_URL;
export const DOWNLOAD_ANLASMALIMATBAA_RAPOR = UZLASMA_BASE_URL + "/uzlasma/download";
export const GET_BIRIM_LIST = UZLASMA_SERVICE_URL;
export const GET_BIRIM_UYE_LIST = UZLASMA_SERVICE_URL;
export const SAVE_KOMISYON_BILGISI = UZLASMA_SERVICE_URL;
export const GET_KOMISYON_BILGISI = UZLASMA_SERVICE_URL;      //DEFGEL_TOU_KOMISYON_BILGISI_LISTELE_SCR
export const DELETE_KOMISYON_BILGISI = UZLASMA_SERVICE_URL;  //DEFGEL_TOU_KOMISYON_BILGISI_SIL_SCR
export const CLOSE_KOMISYON_BILGISI = UZLASMA_SERVICE_URL;  //DEFGEL_TOU_KOMISYON_KAPAT_SCR
export const OPEN_KOMISYON_BILGISI = UZLASMA_SERVICE_URL;  //DEFGEL_TOU_KOMISYON_AC_SCR
export const GET_MUKELLEF_LIST = UZLASMA_SERVICE_URL;     //DEFGEL_TOU_KOMISYON_MUKELLEFI_BILGISI_LISTELE_SCR
export const CHECK_VERGI_ZIYAI_HESAPLAMA = UZLASMA_SERVICE_URL;    //DEFGEL_TOU_VERGI_ZIYAI_CEZASI_HESAPLA_SCR
export const GET_MUKELLEFIN_RAPOR_IHBARNAME_BILGISI = UZLASMA_SERVICE_URL;    //DEFGEL_TOU_MUKELLEFIN_RAPOR_IHBARNAMALERINI_AL_SCR
export const GET_MUKELLEF_TUTANAK_BILGISI = UZLASMA_SERVICE_URL;    //DEFGEL_TOU_TUTANAK_LISTELE_SCR
export const DELETE_MUKELLEF_BILGISI = UZLASMA_SERVICE_URL;        //DEFGEL_TOU_KOMISYON_MUKELLEFI_BILGISI_SIL_SCR
export const GET_RF_DATA = UZLASMA_SERVICE_URL;                   //REF_DATA

/** SMS End Points.. */
const SMS_SERVICE_URL = process.env.REACT_APP_SMS_SERVICE_URL;

/** EVDB End Points.. */
export const EVDB_CALL_SERVICE = UZLASMA_SERVICE_URL;

/** ASSOS End Point */
const ASSOS_SERVICE_URL = process.env.REACT_APP_ASSOS_SERVICE_URL;

export const ASSOS_LOGIN_SERVICE = ASSOS_SERVICE_URL;
export const ASSOS_USER_INFO_SERVICE = UZLASMA_SERVICE_URL;
export const AUTHENTICATION_CSAP_SERVICE_CHANGE_PASSPORT = UZLASMA_SERVICE_URL;
export const SMS_SERVER_FOR_PASSWORD = SMS_SERVICE_URL;



