import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAxios from "../hooks/useAxios";
import { showAlert, isEmpty } from "../utils/site";
import { RESPONSE_TYPE } from "../consts/ResponseType";
import { MSG } from "../consts/Messages";
import { navigate } from "gatsby";
import { MenuContext } from "./MenuContext";
import {pageDefaults,menuSettingDefaults} from "./MenuContext"
import { AUTH_API } from "../consts/Endpoints";


export const AuthContext = createContext({
  logout: () => {}
});


const AuthContextProvider = ({ children }) => {

  
  const [request] = useAxios();
  const [isAuth, setIsAuth] = useState(0);

  const {setPages, setMenuSettings,menuSettings} = useContext(MenuContext);



  const signUp = (username, password, email,callb,errCallb) => {
    request({
      url: AUTH_API+"api/signup",
      method: "post",
      headers: {
        'Content-type': 'application/json'
      },
      data: {
        username: username,
        password: password,
        email: email
      }

    }, (response) => {

      debugger;

      if (response.httpStatus) {
        throw response.message;
      }


      console.log(response);
      showAlert("Kayıt işleminiz başarılı")
      callb();


    },(error) => {
      errCallb(error);
    });
  }



  const login = (userName, passport,callb,errCallb) => {

    request({
      url: AUTH_API+"api/login",
      method: "post",
      headers: {
        'Content-type': 'application/json'
      },
      data: {
        username: userName,
        password: passport
      }

    }, (response) => {

      debugger;

      if (response.httpStatus) {
        throw response.message;
      }

      if(!response.token){
        throw 'Lütfen Site Yöneticisine Başvurun! Token Yok!'
      }

      
      getUserInfo(response.token,callb);


    },(error) => {
      errCallb(error);
    });


  }


  const getUserInfo = (token,callb) => {

    console.log('token '+token);
    request({
      url: AUTH_API+"secapi/userinfo",
      method: "post",
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer '+token
      }
    }, (response) => {

      debugger;

      if (response.httpStatus) {
        localStorage.removeItem('token');
        setIsAuth(2);
        throw response.message;
      }

      setIsAuth(1);
      //sessionStorage.setItem('token',token);
      localStorage.setItem('token',token);
      
      if (response.pages) {
        setPages(response.pages)
      }else {
        setPages(pageDefaults);
      }
      /*if(response.menuSettings){
        setMenuSettings(response.menuSettings)
      }*/
      setMenuSettings([...menuSettingDefaults,'Çıkış Yap']);
      console.log(response);
      callb();
      


    },(error) => {
      console.log(error.response.data);
      authSifirla();
      showAlert(error.response.data.message+ " ", RESPONSE_TYPE.ERROR, "Hata!");
    });











    /* let data = getFormData('userSessionService_getUserSessionInfo', null);
     request({
         url: ASSOS_USER_INFO_SERVICE,
         method: "post",
         headers: {
             ...data
         },
         data: data
     }, (response) => {
         if (!isEmpty(response?.error)) {
             showAlert(response?.messages[0].text, RESPONSE_TYPE.WARNING);
             sessionStorage.removeItem("token");
             setIsAuth(2);
         } else if (response?.data?.apps?.includes(GIB_MODUL.UZLASMA)) {
             setUserInfo({ ...response.data });
             setIsAuth(1);
         } else {  // Uzlaşma modülüne giriş için yetkisi yok ise;
             sessionStorage.removeItem("token");
             showAlert(MSG.UZLASMA_LOGIN_YETKI_YOK, RESPONSE_TYPE.WARNING);
             setIsAuth(2);
         }
     }); */
  }



  const logout = () => {

    // SSO falan olmadığı için ve jwt nin expiration date oldugu için direk session ı siliyoruz
    //sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    setIsAuth(2);
    setPages(pageDefaults);
    setMenuSettings(menuSettingDefaults);
    showAlert("Çıkış İşlemi Başarılı",'info')
    navigate('/app');


    /* if (isEmpty(sessionStorage.getItem("token"))) {
       setUserInfo(null);
       sessionStorage.removeItem("token");
       setIsAuth(2);
     } else {
       let qs = require('qs');
       let data = qs.stringify({
         'assoscmd': ASSOS_LOGIN_TYPE.LOGOUT,
         'rtype': 'json',
         'token': sessionStorage.getItem("token"),
       });
       request({
         url: ASSOS_LOGIN_SERVICE,
         method: "post",
         headers: {
           'Content-Type': 'application/x-www-form-urlencoded'
         },
         data: data
       }, (response) => {
         if (!isEmpty(response?.error)) {
           showAlert(response?.messages[0].text, RESPONSE_TYPE.WARNING);
         } else {
           setUserInfo(null);
           sessionStorage.removeItem("token");
           setIsAuth(2);
         }
       });
     } */
  };


  const authSifirla = () => {
    localStorage.removeItem('token');
    setIsAuth(2);
    setPages(pageDefaults);
    setMenuSettings(menuSettingDefaults);
  }


  /**
  *  Sayfa refresh olduğu zaman; userInfo bilgisi yeniden dolduruluyor. Ve Browser dan manuel routing yaptığında burası çalışır. 
  * Session storage a manuel token eklediğinde ve browserden manuel istek yaptıgında ilk burası değil AuthWrap çalışır.ORda da token varsa içeriye atıyor.
  * Her istekte token ı sorguladıgı için veri çekemez ama içerdeki sayfalarda gezinebilri
  * 
  * OLAYI ÇÖZDÜK auth işini state e bağladık. Ekstradan sessionStorage da da duruyor
  */
  useEffect(() => {
    /*if (sessionStorage.getItem("token") !== null) {
      getUserInfo(sessionStorage.getItem("token"),()=>{});
    } */
    if (localStorage.getItem("token") !== null) {
      getUserInfo(localStorage.getItem("token"),()=>{});
    } 
    else {
      setIsAuth(2);
      setPages(pageDefaults);
    }
  }, [])

  // TODO : burada pages, menuSettings değerlerini de eklemek gerekebilir
  // bu methodlar 1 kere oluşması yeterli ve util func olarak kullanılmamalı çünkü içinde state vs kullanıyoruz. Tam da context in bağlamında bulunmalı
  const values = useMemo(() => ({ isAuth, login, logout,signUp }), [isAuth]);


  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );


}

export default AuthContextProvider;