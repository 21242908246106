exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-amazon-c-admin-page-js": () => import("./../../../src/pages/admin/AmazonCAdminPage.js" /* webpackChunkName: "component---src-pages-admin-amazon-c-admin-page-js" */),
  "component---src-pages-admin-amazon-c-panel-card-detail-js": () => import("./../../../src/pages/admin/AmazonCPanelCardDetail.js" /* webpackChunkName: "component---src-pages-admin-amazon-c-panel-card-detail-js" */),
  "component---src-pages-admin-hepsiburada-c-admin-page-js": () => import("./../../../src/pages/admin/HepsiburadaCAdminPage.js" /* webpackChunkName: "component---src-pages-admin-hepsiburada-c-admin-page-js" */),
  "component---src-pages-admin-hepsiburada-c-panel-card-detail-js": () => import("./../../../src/pages/admin/HepsiburadaCPanelCardDetail.js" /* webpackChunkName: "component---src-pages-admin-hepsiburada-c-panel-card-detail-js" */),
  "component---src-pages-admin-trendyol-c-admin-page-js": () => import("./../../../src/pages/admin/TrendyolCAdminPage.js" /* webpackChunkName: "component---src-pages-admin-trendyol-c-admin-page-js" */),
  "component---src-pages-admin-trendyol-c-panel-card-detail-js": () => import("./../../../src/pages/admin/TrendyolCPanelCardDetail.js" /* webpackChunkName: "component---src-pages-admin-trendyol-c-panel-card-detail-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-dashboard-dashboard-js": () => import("./../../../src/pages/dashboard/Dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-dashboard-js" */),
  "component---src-pages-details-amazon-card-detail-js": () => import("./../../../src/pages/details/AmazonCardDetail.js" /* webpackChunkName: "component---src-pages-details-amazon-card-detail-js" */),
  "component---src-pages-details-amazon-details-js": () => import("./../../../src/pages/details/AmazonDetails.js" /* webpackChunkName: "component---src-pages-details-amazon-details-js" */),
  "component---src-pages-details-hepsiburada-card-detail-js": () => import("./../../../src/pages/details/HepsiburadaCardDetail.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-card-detail-js" */),
  "component---src-pages-details-hepsiburada-details-js": () => import("./../../../src/pages/details/HepsiburadaDetails.js" /* webpackChunkName: "component---src-pages-details-hepsiburada-details-js" */),
  "component---src-pages-details-trendyol-card-detail-js": () => import("./../../../src/pages/details/TrendyolCardDetail.js" /* webpackChunkName: "component---src-pages-details-trendyol-card-detail-js" */),
  "component---src-pages-details-trendyol-details-js": () => import("./../../../src/pages/details/TrendyolDetails.js" /* webpackChunkName: "component---src-pages-details-trendyol-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signinup-sign-in-js": () => import("./../../../src/pages/signinup/SignIn.js" /* webpackChunkName: "component---src-pages-signinup-sign-in-js" */),
  "component---src-pages-signinup-sign-up-js": () => import("./../../../src/pages/signinup/SignUp.js" /* webpackChunkName: "component---src-pages-signinup-sign-up-js" */)
}

