import Swal from 'sweetalert2';
import { MSG } from '../consts/Messages';

export const isEmpty = (value) => {
    return value === undefined || value === null || value === '';
  }


export const showAlert = (message, type, title) => {
    if (isEmpty(title)) {
      Swal.fire({
        text: message,
        icon: type,
        confirmButtonText: MSG.TAMAM
        //backdrop:false,  Ekranda yavaşlama yada scroll kayması olması durumunda false yapılabilir.  
      });
    } else {
      Swal.fire({ text: message, icon: type, title: title, confirmButtonText: MSG.TAMAM });
    }
  
  }