import {  useContext } from "react";
import axios from "axios";
import { isEmpty,showAlert } from "../utils/site";
import { MSG } from "../consts/Messages";
import { RESPONSE_TYPE } from "../consts/ResponseType";
import { LoadingContext } from "../context/LoadingContext";
import { navigate } from "gatsby";

//import { buildKeyGenerator, setupCache,buildWebStorage } from "axios-cache-interceptor/dev"; // for debug
import { buildKeyGenerator, setupCache,buildWebStorage } from "axios-cache-interceptor";
import { TRENDYOL_API } from "../consts/Endpoints";

//cache'lenmek istenen verilerin ne kadar saklanacağını belirleyen değer.Default 5 dk
const FIVE_MINUTE = 1000 * 60 * 5;

/*****************INTERCEPTOR */
// Interceptor kullanınca cors hatası alınıyordu. Fakat per request için cache ayarı verilebiliyordu iyiydi ve Yepyeniydi
// Çözdük. Dökümantasyonunda yazıyor. Backend tarafında Cache-Control, Pragma and Expires header larını Access-Control-Allow-Headers listesine eklemek gerekiyormuş

const _axiosInstance = axios.create({
  //baseURL: TRENDYOL_API,
});
const _axios = setupCache(_axiosInstance,{
  //debug: console.log , // for debug 
  ttl:FIVE_MINUTE,
  methods: ['get', 'post', 'head'],
/*  generateKey: buildKeyGenerator((request ) => ({ // Bu digite çevirerek verilen değerleri birleştirerek bir değer oluşturuyor. DEfault un da method, baseURL, query, params, data and url var
    method: request.method,
    url: request.url,
    custom: logicWith(request.method, request.url)
  })) */
  // storage: buildWebStorage(localStorage, 'axios-cache:') // for persistent cache for page refresh SSR hatası veriyor build alırken
});








const useAxios = ({maxAge,cache}={}) => {

  const _maxAge = maxAge ?? FIVE_MINUTE
  const _cache = cache ?? false;
  let _cacheObj;
  if(_cache === true){
    _cacheObj = {
      cache:{
        ttl:_maxAge
      }
    }
  }else {
    _cacheObj = {
      cache:false
    }
  }

  

  //const { setIsLoading } = useContext(LoadingContext);
  

  const request = async (params, callBackFunc, errorCallBackFunc) => {
    try {
      //setIsLoading(true);
      const result = await _axios.request({...params,..._cacheObj});
      callBackFunc(result?.data);
    } catch (error) {
      console.log(error)
      debugger;
      if(errorCallBackFunc){
        errorCallBackFunc(error);
      }else{
        showAlert(error+ " ", RESPONSE_TYPE.ERROR, "Hata!");
      }
      
      
    } finally {
      //setIsLoading(false);
    }
  };

  

  return [request];
};

export default useAxios;



/**
 * Örnek kullanım
 *
 * Cach li : 
 * const [request] = useAxios({cache:true,maxAge:600000}); // maxAge optional. default u 5 dk
 * 
 * Cach siz :
 * const [request] = useAxios();
 * 
 * 
 * request({
          url: ASSOS_LOGIN_SERVICE,
          method: "post",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        }, (response) => {
          if (!isEmpty(response?.error)) {
            showAlert(response?.messages[0].text, RESPONSE_TYPE.WARNING);
          } else {
            sessionStorage.setItem("token", response.token);
            getUserInfo();
          }
        });
 * 
 * 
 * 
 */