import { Backdrop, CircularProgress } from '@mui/material';
import React, { createContext, useMemo, useState } from 'react'

export const LoadingContext = createContext();

const LoadingContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading]);
    return (
        <LoadingContext.Provider value={value}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={isLoading}
                transitionDuration={{ enter: 1, exit: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </LoadingContext.Provider>
    )
}

export default LoadingContextProvider;
