/**
 *  Frontend de gösterilecek olan Mesaj bilgilerinin tanımlandığı CONST.. 
 */

export const MSG = Object.freeze({
 
    //Base mesajlar,
    EVET: "Evet",
    HAYIR: "Hayır",
    TAMAM: "Tamam",
    EKLE: "Ekle",
    SIL: "Sil",
  
    //CROD işlemlere ilişkin mesajlar..
    EKLEME_YAPILDI: "Ekleme işlemi yapıldı!",
    KAYDET_YAPILDI: "Kaydetme işlemi yapıldı.!",
    SILME_SORGU_MSG: "Seçili Kayıt Silinsin mi?",
    SILME_YAPILDI: "Seçilen Kayıt Silindi!",
    SILME_VT_KAYDI_VAR: "Bu Kayıt Veri Tabanında Kayıtlıdır. Silinemez.!",
    LOGIN_CONTROL: "LOGIN Girişi yapılmamış, giriş yapınız.!",
    INFO_DEGISIKLIK_YOK: "Kaydedilecek Değişiklik Bulunmamaktadır!",
    ERROR_CROD_BASE: "Gerçekleştirdiğiniz işlemde hata oluştu! Lütfen sistem yönetisine başvurun.",
    SERVIS_BAGLANTI_YAPILAMADI: "Servis ile bağlatı yapılamadı!",
    ISLEM_BASARILI: "İşlem Başarılı.",
  
    //Params mesajlar..
    ZORUNLU_ALAN: "Zorunlu Alanlara Giriş Yapınız!",
    ZORUNLU_ALAN_PARAMLI: " Girilmesi Gereken Bir Alandır!",
    ZORUNLU_ALAN_EKLENEN_KAYIT_ETKIN_OLMALI: "Yeni eklenen bir kayıt ETKIN olmalıdır. ETKIN olmayan yeni bir kayıt eklemeye çalışılıyor.!",
  
    TABLODAN_BIR_SATIR_SECINIZ: "Tablodan Bir Satır Seçiniz!",
    TABLODAN_SECIM_YAPINIZ: "Tablodan seçim yapınız!",
    SORGULAMA_KAYIT_BULUNAMADI: "Sorgulama kriterlerine ilişkin kayıt bulunamadı!",
    SORGULAMA_KRITERI_GIR: "Herhangi Bir Sorulama Kriteri Girmelisiniz!",
    BASLANGIC_BITIS_TARIH_KONTROL: "Başlangıç Tarihi Bitiş Tarihinden BÜYÜK olamaz.!",
    BASLANGIC_TARIHI_GIRINIZ: "Başlangıç Tarihi Giriniz!",
  
    // LOGIN actions
    LOGOUT_SUCCES: "Çıkış işlemi başarılı.",
    UZLASMA_LOGIN_YETKI_YOK : "Uzlaşma Modülüne erişim yetkiniz bulunmamaktadır!",
    PASSWORD_CHANGE : "Şifre Başarı ile Değiştirildi.",
    PASSWORD_CHANGE_QUESTION : "Şifreyi değiştirmek istiyor musunuz?",
    PASSWORD_FORGOT_SENDTOSMS : "Kullanıcı kodu ve Telefon numarası alanları boş olamaz.!",
    SEND_SMS_SUCCESS : "SMS Başarı ile Gönderildi.",
  
    // UZLASTIRMA mesajlar..
    UZLASTIRMA_KAYDEDILDI: "Uzlaştırma başarı ile kaydedildi.",
    UYE_AYNI_KAYIT_EKLEME: "Aynı 'Üye Tipi' için ekleme yapılmış! İkinci kez ekleme yapılamaz.",
    KOMISYON_BILGI: "Komisyon için 3 veya 5 kişiden oluşmak üzere; 1 Başkan, 2 veya 4 üye ekleme yapılarak Komisyon oluşturulabilir!",
    BASKAN_EKLE_UYARI: "Komisyon'a \"Başkan\" eklenmedi! Lütfen \"Başkan\" bilgisini giriniz.",
    KOMISYON_OLUSTURULDU: "Komisyon Başarılı Şekilde Oluşturulmuştur.",
    KOMISYON_SIL_UYARI: "Seçili Komisyona ait tüm bilgiler silinecek. Devam etmek istiyor musunuz?",
    KOMISYON_KAPAT_UYARI: "Seçilen Komisyon Kapatılacak. Devam etmek istiyor musunuz?",
    KOMISYON_AC_UYARI: "Seçilen Komisyon açılacak. Devam etmek istiyor musunuz?",
    KOMISYON_SEC_UYARI: "Komisyon Seçimi Yapılmadı! Lütfen Komisyon Seçiniz.",
    MUKELLEF_SEC_UYARI: "Mükellef Seçimi Yapılmadı! Lütfen Mükellef Seçiniz.",
    UZLASTIRMA_SONUC_IHBARNAME_KAYDI_YOK: "Seçilen Mükellefe Ait İhbarname Kaydı Yok! İhbarname Kaydı Yapıldıktan Sonra Tutanak İşlemlerine Devam edebilirsiniz."
  
  });